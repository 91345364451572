import { axios } from "../services";
export const setHeaders = () => {
  const login = JSON.parse(localStorage.getItem("login"));
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "http://localhost:3000"; 
  // axios.defaults.headers.common["Content-type"] = "application/json";
  if (login) {
    // axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Id"] = login["id"];
    axios.defaults.headers.common["Assigned-Token"] = login["assigned-token"];
    axios.defaults.headers.common["Device-Id"] = login["device-id"];
    axios.defaults.headers.common["User-Token"] = login["user-token"];
    axios.defaults.headers.common["Bearer-Token"] = login["bearer-token"];
    axios.defaults.headers.common["Authorization"] = `Token ${login["bearer-token"]}`;
    return true;
  }
  return false;
};

export const deleteHeader = () => {
  delete axios.defaults.headers.common["Content-Type"]
  const at = delete axios.defaults.headers.common["Assigned-Token"];
  const di = delete axios.defaults.headers.common["Device-Id"];
  const id = delete axios.defaults.headers.common["Id"];
  const ut = delete axios.defaults.headers.common["User-Token"];
  const bt = delete axios.defaults.headers.common["Bearer-Token"];
  const au = delete axios.defaults.headers.common["Authorization"];
  if (at && di && id && ut && bt && au) {
    return true;
  } else {
    return false;
  }
};

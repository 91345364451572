import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {},
};

export const loginSlice = createSlice({
  name: "loginState",
  initialState,
  reducers: {
    deleteLogin: (state) => {
      state.value = {};
    },
    setLogin: (state, action) => {
      state.value += action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLogin, deleteLogin } = loginSlice.actions;

export default loginSlice.reducer;
